import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import { ShopAction, ShopActionTypes } from '../../types/entities/Shop';
import { $api } from '../../http';

interface queryShopParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  title?: string;
  status?: string;
  cityId?: string;
}

export const fetchShops = (queryParams: queryShopParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    title,
    status,
    cityId,
  } = queryParams;

  const urlShopParams = new URLSearchParams();
  urlShopParams.append('limit', limit);
  urlShopParams.append('page', page);
  urlShopParams.append('sortId', sortId);
  if (title !== undefined) urlShopParams.append('title', title);
  if (status !== undefined) urlShopParams.append('status', status);
  if (cityId !== undefined) urlShopParams.append('cityId', cityId);

  return async (dispatch: Dispatch<ShopAction>) => {
    try {
      dispatch({ type: ShopActionTypes.FETCH_SHOP });
      const response = await $api.get(
        `${ApiUrl.SHOP}?${urlShopParams.toString()}`,
      );
      dispatch({
        type: ShopActionTypes.FETCH_SHOPS,
        payload: response.data.items,
      });
      dispatch({
        type: ShopActionTypes.SET_TOTAL_SHOP_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: ShopActionTypes.SET_SHOP_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: ShopActionTypes.FETCH_SHOPS_ERROR,
        payload: 'Произошла ошибка при загрузке магазинов',
      });
    }
  };
};
